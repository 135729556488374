<template>
  <div v-if="loaded">
    <div class="row row-cards">
      <div
        class="col-md-12"
        v-for="insertionOrder in insertionOrders"
        :key="`insertion-order-${insertionOrder.insertionOrder.insertionorderid}`"
      >
        <div
          class="card mb-3"
          v-if="
            insertionOrder.insertionOrder.installmentquantity == 1 &&
            insertionOrder.insertionOrder.installmentstatus != 'n'
          "
        >
          <div class="card-header">
            <div class="me-auto">
              <h3 class="card-title text-capitalize">
                <strong>{{ insertionOrder.planName }} Plan</strong>
              </h3>

              <div class="text-muted">
                You are on a <strong>{{ insertionOrder.planName }}</strong> plan
                billed
                <strong>{{ insertionOrder.billingFrequency }}.</strong>

                <span v-if="insertionOrder.renewsOn">
                  Your next payment is on
                  {{ prettyDate(insertionOrder.renewsOn) }}.
                </span>
              </div>
            </div>

            <router-link
              :to="`/newupgrade?io=${insertionOrder.insertionOrder.insertionorderid}`"
              class="btn btn-primary"
            >
              Manage Subscription
            </router-link>
          </div>
          <div class="table-responsive">
            <table class="table card-table">
              <thead>
                <tr>
                  <th>What's included?</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in insertionOrder.insertionOrder
                    .insertionOrderItems"
                  :key="`ioi-${item.id}`"
                >
                  <td>{{ item.itemName }}</td>
                  <td>
                    {{ money(adjustedAmount(insertionOrder, item.amount)) }}
                  </td>
                </tr>
                <tr v-if="insertionOrder.discounts > 0">
                  <td class="">Credits &amp; Discounts</td>
                  <td>
                    ({{
                      money(
                        adjustedAmount(insertionOrder, insertionOrder.discounts)
                      )
                    }})
                  </td>
                </tr>
                <tr class="">
                  <td class="text-right"><strong>TOTAL</strong></td>
                  <td>
                    {{
                      money(
                        adjustedAmount(
                          insertionOrder,
                          insertionOrder.insertionOrder.totalamount
                        )
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mb-3" v-else>
          <div class="card-header">
            <div class="me-auto">
              <h3 class="card-title text-capitalize">
                <strong>Your {{ insertionOrder.planName }} Plan</strong>
              </h3>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table card-table">
              <tbody>
                <tr
                  v-for="item in insertionOrder.insertionOrder
                    .insertionOrderItems"
                  :key="`ioi-${item.id}`"
                >
                  <td>{{ item.itemName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3" v-if="sites.length > 0">
      <div class="row">
        <div
          class="col-md-12"
          v-for="site in sites"
          :key="`${site.siteID}-site`"
        >
          <site-plan-card :site="site"></site-plan-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillingService from "../../../services/BillingService";
import SitePlanCard from "./SitePlanCard.vue";
import { format, parseJSON } from "date-fns";

export default {
  components: { SitePlanCard },
  name: "account.billing.plan",
  computed: {
    sites() {
      let excludeSites = this.insertionOrders
        .map((es) => es.sites.map((s) => s.siteid))
        .flat();
      return [...this.$auth.activeCompany.sites]
        .filter(
          (s) => s.listingType == "free" && !excludeSites.includes(s.siteID)
        )
        .sort((a, b) => a.siteRank - b.siteRank);
    },
  },
  data() {
    return {
      insertionOrders: [],
      loaded: false,
    };
  },
  methods: {
    prettyDate(date) {
      return format(parseJSON(date), "MMM dd, yyyy");
    },
    money(number) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
    adjustedAmount(_, amount) {
      return Math.round(amount, 2);
    },
  },
  async mounted() {
    this.insertionOrders = await BillingService.getCurrentInsertionOrders(
      this.$auth.activeCompany
    );
    this.loaded = true;
  },
};
</script>
