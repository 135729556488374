import APIService from "./APIService";
import { getInstance } from "../auth/auth";
import { ca } from "date-fns/locale";

const http = APIService.http;

export default {
  async getSubscriptions() {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = "api/Billing/all-subscriptions";
    let res = await http.get(url, authHeaders);
    return res.data;
  },
  async getSubscriptionDetail(subscriptionId) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = `api/Billing/subscription-detail/${subscriptionId}`;
    let res = await http.get(url, authHeaders);
    return res.data;
  },
  async convertSubscriptionDry(subscriptionId) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = `api/Fulfillment/convert-yearly-subscription/${subscriptionId}`;
    let res = await http.post(url, null, authHeaders);
    return res.data;
  },
  async convertSubscriptionActual(subscriptionId) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = `api/Fulfillment/convert-yearly-subscription/${subscriptionId}/false`;
    let res = await http.post(url, null, authHeaders);
    return res.data;
  },
  async fulfillSingleSubscriptionDry(subscriptionId) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = `api/Fulfillment/full-sync-subscription/${subscriptionId}`;
    let res = await http.post(url, null, authHeaders);
    return res.data;
  },
  async fulfillSingleSubscriptionActual(subscriptionId) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = `api/Fulfillment/full-sync-subscription/${subscriptionId}/false`;
    let res = await http.post(url, null, authHeaders);
    return res.data;
  },
  async getCompanyRep(company, repid) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let url = "api/Billing/companyrep";
    if (repid) {
      url += `/${repid}`;
    }
    let res = await http.post(url, company, authHeaders);
    return res.data;
  },
  async sendUnableToUpgrade(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Billing/unabletoupgrade",
      company,
      authHeaders
    );
    return res.data;
  },
  async sendSalesAmountMismatch(company) {
    let res = await http.post("api/Billing/SalesAmountMismatch",company);
      return res.data;
  },
  async setDefaultPaymentMethod(billingInfo) {
    let res = await http.post(
      "api/Billing/SetDefaultPaymentMethod",
      billingInfo
    );
    return res.data;
  },
  async addPaymentMethod(billingInfo) {
    let res = await http.post("api/Billing/AddPaymentMethod", billingInfo);
    return res.data;
  },
  async getPaymentMethods(company) {
    let res = await http.post("api/Billing/PaymentMethods", company);
    return res.data;
  },
  async getInvoices(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Billing/GetInvoices", company, authHeaders);
    return res.data;
  },
  async getCurrentInsertionOrders(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Billing/CurrentInsertionOrders",
      company,
      authHeaders
    );
    return res.data;
  },
  async getIIDByIOID(filter) {
    let res = await http.post("api/Billing/GetIIDByIOID", filter);
    return res.data;
  },
  async validateInvoice(filter) {
    let res = await http.post("api/Billing/ValidateInvoice", filter);
    return res.data;
  },
  async validateInsertionOrder(filter) {
    let res = await http.post("api/Billing/ValidateInsertionOrder", filter);
    return res.data;
  },
  async loadStates() {
    let res = await http.post("api/Billing/GetStates");
    return res.data;
  },
  async loadProvinces(filter) {
    let res = await http.post("api/Billing/GetProvinces", filter);
    return res.data;
  },
  async loadCountries(filter) {
    let res = await http.post("api/Billing/GetCountries", filter);
    return res.data;
  },
  async loadInvoice(filter) {
    let res = await http.post("api/Billing/LoadInvoice", filter);
    return res.data;
  },
  async loadInsertionOrder(filter) {
    let res = await http.post("api/Billing/LoadInsertionOrder", filter);
    return res.data;
  },
  async loadInvoiceItems(filter) {
    let res = await http.post("api/Billing/LoadInvoiceItems", filter);
    return res.data;
  },
  async LoadInsertionOrderItems(filter) {
    let res = await http.post("api/Billing/LoadInsertionOrderItems", filter);
    return res.data;
  },
  async loadSiteLogos(filter) {
    let res = await http.post("api/Billing/LoadSiteLogos", filter);
    return res.data;
  },
  async isPaidInvoice(filter) {
    let res = await http.post("api/Billing/IsPaidInvoice", filter);
    return res.data;
  },
  async isInventoryAvailable(filter) {
    let res = await http.post("api/Billing/IsInventoryAvailable", filter);
    return res.data;
  },
  async checkSoldOutItem(filter) {
    let res = await http.post("api/Billing/CheckSoldOutItem", filter);
    return res.data;
  },
  async checkInActiveItem(filter) {
    let res = await http.post("api/Billing/CheckInActiveItem", filter);
    return res.data;
  },
  async checkout(order) {
    let res = await http.post("api/Billing/CheckOut", order);
    return res.data;
  },
  async newCheckout(insertionorderid, order) {
    let res = await http.post(
      `api/Billing/NewCheckOut/${insertionorderid}`,
      order
    );
    return res.data;
  },
  async paybycheck(order) {
    let res = await http.post("api/Billing/PayByCheck", order);
    return res.data;
  },
};
