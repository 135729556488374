<template>
  <div class="card mb-3" v-if="loaded">
    <div class="card-header">
      <h3 class="card-title me-auto">{{ fullSite.sitename }}</h3>

      <router-link
        :to="`/newupgrade?site=${fullSite.siteid}`"
        class="btn btn-primary"
      >
        Manage Subscription
      </router-link>
    </div>
    <div class="card-body">
      <span class="me-3">
        <a
          :href="`https://${fullSite.directoryurl}/company/${$auth.activeCompany.companyId}/`"
          target="_blank"
        >
          {{ fullSite.sitename }}
        </a>
      </span>
      <span class="badge bg-blue-lt">{{ listingType }}</span>
    </div>
  </div>
</template>

<script>
import SiteService from "../../../services/SiteService";

export default {
  name: "account.billing.site-plan-card",
  props: {
    site: Object,
  },
  computed: {
    listingType() {
      if (!this.site.listingType) return;

      return this.site.listingType.toUpperCase();
    },
  },
  data() {
    return {
      fullSite: {},
      loaded: false,
    };
  },
  async mounted() {
    this.fullSite = await SiteService.getSite(this.site.siteID);
    this.loaded = true;
  },
};
</script>
